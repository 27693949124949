import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import bundledSpark from '../../images/installing-spark/html/bundled-spark.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`This guide is Part 2 in a 3-Part series. You can also check out `}<a parentName="p" {...{
          "href": "/installing-spark/html-environment-setup"
        }}>{`Part 1 - Setting Up Your Environment`}</a>{` and `}<a parentName="p" {...{
          "href": "/installing-spark/html-add-components"
        }}>{`Part 3 - Adding Components`}</a>{`.`}</p>
    </blockquote>
    <h1>{`Part 2: Installing Spark in an HTML Project`}</h1>
    <h2>{`Installing Spark`}</h2>
    <p>{`In this guide we’ll walk you through installing Spark into a site without a
JavaScript framework.`}</p>
    <p>{`Our `}<a href="https://github.com/sparkdesignsystem/spark-starter-html/" target="_blank">{`starter app examples`}</a>{` are also available for reference:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/kitchen-sink"
        }}>{`'kitchen-sink' branch`}</a>{` (Spark Installed with component examples.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/without-spark"
        }}>{`'without-spark' branch`}</a>{` (All of the prerequisites before Spark Installation.)`}</li>
    </ul>
    <p>{`For instructions on setting up Spark in other environments, check out the
guides for `}<a parentName="p" {...{
        "href": "/installing-spark/angular"
      }}>{`Angular`}</a>{` and `}<a parentName="p" {...{
        "href": "/installing-spark/react"
      }}>{`React`}</a>{` development environments.`}</p>
    <div className="sprk-u-Measure" style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}>
  <iframe style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }} src="https://www.youtube.com/embed/u9Cz3IJWJe4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Installing Spark in an HTML Project Part 2/3 - Configuring Spark"></iframe>
    </div>
    <h2>{`Install the Spark Package`}</h2>
    <ol>
      <li parentName="ol">{`First, use `}<inlineCode parentName="li">{`npm`}</inlineCode>{` to install the Spark package. This will download the
JavaScript and CSS needed to add the Spark components.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --save-dev @sparkdesignsystem/spark
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`This will download all the JavaScript and CSS needed to render the Spark
components into your `}<inlineCode parentName="li">{`node_modules`}</inlineCode>{` folder. To access
them in your compiled website, import them in your script. Webpack will
include them in your bundled site JavaScript. Copy this import code into your `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` file:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// import the Spark JavaScript
import spark from "@sparkdesignsystem/spark/es5/spark";
import sparkPrerender from "@sparkdesignsystem/spark/es5/sparkPrerender";

// optional (see below)
import "@sparkdesignsystem/spark/es5/sparkPolyfills";

// import the Spark CSS
import "@sparkdesignsystem/spark-styles/_spark.scss";

// initialize Spark
sparkPrerender();
spark();
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`sparkPolyfills`}</strong>{` - This file loads a few polyfills that Spark requires. If your application is already polyfilling the items below, you don't need to load this file.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul"><inlineCode parentName="li">{`Promise`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`Array.from`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`String.includes`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`Array.find`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`NodeList.forEach`}</inlineCode></li>
        <li parentName="ul"><inlineCode parentName="li">{`classList`}</inlineCode>{` on SVG Elements`}</li>
      </ul>
    </blockquote>
    <p>{`The Spark assets will now be included when you compile your site.
Run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run build
`}</code></pre>
    <p>{`You can verify that this is the case by checking the compiled `}<inlineCode parentName="p">{`bundle.css`}</inlineCode>{` and
`}<inlineCode parentName="p">{`main.js`}</inlineCode>{` in your solution. The compiled Spark CSS and JavaScript will show
here:`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={bundledSpark} alt="Minified CSS in bundle.css and minified JS in main.js" />
    </div>
    <h2>{`Configure Spark`}</h2>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Finally, in `}<inlineCode parentName="li">{`index.html`}</inlineCode>{`, find or create the container of your main content and add the 
`}<inlineCode parentName="li">{`data-sprk-main`}</inlineCode>{` attribute. This attribute is required for some
Spark components to correctly handle focus changes.`}</li>
    </ol>
    <h3>{`Notes:`}</h3>
    <ul>
      <li parentName="ul">{`Do not put `}<inlineCode parentName="li">{`data-sprk-main`}</inlineCode>{` on the `}<inlineCode parentName="li">{`<body>`}</inlineCode>{` tag.`}</li>
      <li parentName="ul">{`The main content container cannot contain the Spark Masthead component.`}</li>
      <li parentName="ul">{`Put this container above the `}<inlineCode parentName="li">{`<script src="main.js"></script>`}</inlineCode>{` line.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div data-sprk-main>
  // Main Content
</div>
`}</code></pre>
    <h2>{`Next Steps`}</h2>
    <p>{`You’re ready to start `}<a parentName="p" {...{
        "href": "/installing-spark/html-add-components"
      }}>{`adding Spark components`}</a>{` to
your site.`}</p>
    <h2>{`Additional Topics`}</h2>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-icons"
        }}>{`Icon Installation Guide`}</a>{` for importing the Spark SVG icon set`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/installing-spark/installing-fonts"
        }}>{`Font Installation Guide`}</a>{` for instructions on using the Rocket Sans font`}</li>
    </ul>
    <h2>{`Troubleshooting`}</h2>
    <p>{`Here are some issues that you might encounter while setting up Spark and
how to fix them:`}</p>
    <p><strong parentName="p">{`Issue:`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`Uncaught ReferenceError: SPRK_CURRENT_VERSION is not defined
`}</code></pre>
    <p>{`Make sure you're importing Spark from the correct directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import spark from "@sparkdesignsystem/spark/es5/spark";
`}</code></pre>
    <p>{`This is not the correct directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import spark from "@sparkdesignsystem/spark/spark";
`}</code></pre>
    <hr></hr>
    <p><strong parentName="p">{`Issue: Spark.js is being called, but none of my components are being initialized.`}</strong></p>
    <p>{`Make sure you're calling `}<inlineCode parentName="p">{`spark()`}</inlineCode>{` `}<em parentName="p">{`after`}</em>{` the DOM is loaded. You may need
to move the `}<inlineCode parentName="p">{`<script>`}</inlineCode>{` tag in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` to the bottom of the file.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Issue: The Webpack build is completing without errors but Spark JS and
CSS are not being applied to my site.`}</strong></p>
    <p>{`Make sure you're referencing your JavaScript and CSS files correctly in 
`}<inlineCode parentName="p">{`index.html`}</inlineCode>{`. In our example, we needed to change the import from 
`}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{` to `}<inlineCode parentName="p">{`main.js`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      